<template>
  <div>
    bookmarks
  </div>
</template>

<script>
export default {
  name:"Bookmarks"
}
</script>

<style>

</style>